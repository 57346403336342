import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Content from "../../components/content"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import NewsAll from '../../components/news-all'
import Img from "gatsby-image"

const ArchivePage = () => {
    return (
        <Layout>
            <SEO title="Water Information" />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">Water Information</h1>
                        </div>

                    <div class="image is-16by9"><iframe class="has-ratio" width="1545" height="731" src="https://www.youtube.com/embed/PVCmi-T6Gxk" title="2024 AWBD Winter Preparedness PSA" 
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>

                    <div className="title-section">
                        <h3 className="title is-size-2">Additional Resources</h3>
                        <Link to="/smart-meters" className="button is-primary is-large">Smart Meters</Link>&nbsp;&nbsp; <a 
                        href="/static/6687a6f5d2778440617fdfba806cd1d4/CCR_2020_Fort_Bend_Co_MUD_23_2e911b53cc.pdf" className="button is-primary is-large">Drinking Water Quality Report</a> &nbsp;&nbsp; <a
                        href="/static/bca62336f65e2a2921cca87c0e7d5b87/Insert_printed_at_AVR_FB_23_FLUSHING_NOTICE_12_12_24_1_1f11cdf64b.pdf" className="button is-primary is-large">Waterline Flushing Notice</a>

                    </div>
                    
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default ArchivePage




